import { Link } from 'gatsby';
import React from 'react';
import caretRightIcon from '../../images/library/caretRight.svg';
import styles from './LibraryBreadcrumb.module.scss';

interface LibraryPostPageProps {
  category?: { name: string; url: string };
  collection?: { name: string; url: string };
}

const Caret = () => (
  <img
    className={styles.caret}
    src={caretRightIcon}
    alt=""
    role="presentation"
  />
);

const LibraryBreadcrumb: React.SFC<LibraryPostPageProps> = ({
  category,
  collection,
}) => {
  return (
    <div className={styles.breadcrumb}>
      <Link to="/library/">Library</Link>
      <Caret />
      {!!category && (
        <>
          <Link to={category.url}>{category.name}</Link>
          <Caret />
        </>
      )}
      {!!collection && (
        <>
          <Link to={collection.url}>{collection.name}</Link>
          <Caret />
        </>
      )}
    </div>
  );
};

export default LibraryBreadcrumb;
