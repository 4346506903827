import { Link } from 'gatsby';
import React, { useState } from 'react';
import templateIcon from '../../images/library/template.svg';
import { LibraryPost } from '../../types';
import SignUpModal from '../signUpModal/SignUpModal';
import UntitledButton from '../ui/UntitledButton';
import getTemplateUrl from './getTemplateUrl';
import LibraryBreadcrumb from './LibraryBreadcrumb';
import LibraryCreatorName from './LibraryCreatorName';
import LibraryCreatorPicture from './LibraryCreatorPicture';
import styles from './LibraryPostPage.module.scss';
import LibraryPostsList from './LibraryPostsList';
import useOpenTemplate from './useOpenTemplate';

interface LibraryPostPageProps {
  post: LibraryPost;
}

const LibraryPostPage: React.SFC<LibraryPostPageProps> = ({ post }) => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState<boolean>(false);

  const openTemplate = useOpenTemplate(post.slug, () =>
    setIsSignUpModalOpen(true),
  );

  const footerContents = (
    <div className={styles.copyButtonContainer}>
      <UntitledButton
        onClick={openTemplate}
        track={{ label: 'library-copy', library: post.slug }}
        variant="primary"
      >
        <img
          className={styles.templateIcon}
          src={templateIcon}
          alt=""
          role="presentation"
        />
        Copy into Slab
      </UntitledButton>
    </div>
  );

  const creatorContents = (
    <div className={styles.creatorContainer}>
      <LibraryCreatorPicture small {...post.creator} />
      <div className={styles.creatorName}>
        <LibraryCreatorName {...post.creator} />
      </div>
    </div>
  );

  const relatedTitle =
    post.collection && post.collection.posts.length > 1
      ? `Related examples in ${post.collection.name}`
      : 'Related examples';
  const collectionPosts = post.collection?.posts.filter(
    (p) => p.slug !== post.slug,
  );
  const relatedPosts = (post.related || []).concat(collectionPosts || []);

  const description = (
    <div
      className={styles.longDescription}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: post.longDescription || post.description,
      }}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div>
          <div className={styles.back}>
            <LibraryBreadcrumb
              category={post.category}
              collection={post.collection}
            />
          </div>
          {post.creator.url ? (
            <a href={post.creator.url} target="_blank" rel="noreferrer">
              {creatorContents}
            </a>
          ) : (
            creatorContents
          )}
          <h1 className={styles.title}>{post.title}</h1>
          <div className={styles.desktopLongDescription}>{description}</div>
        </div>
        {relatedPosts.length > 0 && (
          <div className={styles.desktopRelated}>
            <div className={styles.relatedHeader}>{relatedTitle}</div>
            <LibraryPostsList posts={relatedPosts.slice(0, 2)} card />
            {relatedPosts.length > 2 && post.collection && (
              <div className={styles.relatedMore}>
                <Link to={post.collection.url}>
                  View all {post.collection.posts.length} templates in
                  collection
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.template}>
        <div className={styles.pageSheet}>
          <div
            className={styles.content}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        <div className={styles.desktopFooter}>{footerContents}</div>
      </div>
      <div className={styles.mobileFooter}>{footerContents}</div>

      <div className={styles.mobileBottomContent}>
        <div className={styles.mobileLongDescription}>{description}</div>
        {relatedPosts.length > 0 && (
          <>
            <div className={styles.relatedHeader}>{relatedTitle}</div>
            <LibraryPostsList posts={relatedPosts.slice(0, 3)} />
          </>
        )}
      </div>
      {isSignUpModalOpen && (
        <SignUpModal
          onClose={() => setIsSignUpModalOpen(false)}
          loginUrl={getTemplateUrl(post.slug)}
        />
      )}
    </div>
  );
};

export default LibraryPostPage;
