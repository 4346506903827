import {
  LibraryCategoriesYaml,
  LibraryCategoriesYamlCollections,
  LibraryPostsYaml,
  Maybe,
} from '../graphql-types';
import { LibraryCategory, LibraryCollection, LibraryPost } from '../types';
import {
  libraryCategoryPath,
  libraryCollectionPath,
  libraryPostPath,
} from './path';

const getLibraryPostNodePath = (node: Maybe<LibraryPostsYaml>) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  node && libraryPostPath(node.fields!.slug!, node.type!);

export const toLibraryPost = (node: LibraryPostsYaml) =>
  ({
    ...node,
    category: {
      ...node.category,
      slug: node.category?.fields?.slug,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      url: libraryCategoryPath(node.category!.fields!.slug!),
    },
    collection:
      node.category &&
      node.collection &&
      toLibraryCollection(node.category, node.collection),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    slug: node.fields!.slug!,
    path: getLibraryPostNodePath(node),
    originalURL: node.url || undefined,
    related: node.related?.map((relatedNode) => ({
      ...relatedNode,
      path: getLibraryPostNodePath(relatedNode),
    })),
  } as LibraryPost);

export const toLibraryCategory = (node: LibraryCategoriesYaml) => {
  const collections: LibraryCollection[] = (node.collections ?? []).map(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (collectionNode) => toLibraryCollection(node, collectionNode!),
  );

  return {
    ...node,
    collections,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    slug: node.fields!.slug,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    url: libraryCategoryPath(node.fields!.slug!),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    posts: (node.posts || []).map((postNode) => toLibraryPost(postNode!)),
  } as LibraryCategory;
};

const toLibraryCollection: (
  categoryNode: LibraryCategoriesYaml,
  collectionNode: LibraryCategoriesYamlCollections,
) => LibraryCollection = (categoryNode, collectionNode) => {
  return {
    ...collectionNode,
    category: {
      name: categoryNode.name,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      url: libraryCategoryPath(categoryNode.fields!.slug!),
    },
    posts: (collectionNode?.posts || []).map((postNode) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      toLibraryPost(postNode!),
    ),
    slug: collectionNode.slug,
    url: libraryCollectionPath(collectionNode.slug),
  } as LibraryCollection;
};
