import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import AriaModal from 'react-aria-modal';
import logo from '../../images/logo.svg';
import OneTapInlineContainer, {
  OneTapDisplayState,
} from '../home/OneTapInlineContainer';
import UntitledStyle from '../layout/UntitledStyle';
import RegisterForm from '../register/RegisterForm';
import VendorButton from '../register/VendorButton';
import Button from '../ui/Button';
import UntitledButton from '../ui/UntitledButton';
import styles from './SignUpModal.module.scss';

interface SignUpModalProps {
  onClose: () => void;
  loginUrl?: string;
}

const SignUpModal = ({ onClose, loginUrl }: SignUpModalProps) => {
  const redirectPath = loginUrl?.startsWith('/app')
    ? loginUrl.replace('/app', '')
    : loginUrl;

  const [isWithEmail, setIsWithEmail] = useState<boolean>(false);
  const [oneTapState, setOneTapState] = useState<OneTapDisplayState>('loading');

  return (
    <AriaModal
      dialogClass={styles.modal}
      titleText="Sign up for Slab"
      onExit={onClose}
      verticallyCenter
    >
      <div className={styles.modalClose}>
        <Button onClick={onClose} unstyled>
          ✕
        </Button>
      </div>
      <UntitledStyle>
        <div className={styles.content}>
          <Link to="/">
            <img className={styles.logo} src={logo} alt="Slab" />
          </Link>
          <div className={styles.title}>Sign up for Slab</div>
          <div className={styles.subtitle}>
            To copy this template, sign in to Slab. You&apos;ll be able to
            customize this or make new posts.
          </div>
          {!isWithEmail && (
            <>
              <div
                className={classNames(styles.googleSignIn, {
                  [styles.hidden]:
                    oneTapState === 'loading' || oneTapState === 'selected',
                })}
              >
                {oneTapState === 'hidden' ? (
                  <VendorButton platform="google" redirectPath={redirectPath} />
                ) : (
                  <OneTapInlineContainer
                    referral=""
                    onDisplayChange={setOneTapState}
                    redirectPath={redirectPath}
                  />
                )}
              </div>
              <div className={styles.withEmail}>
                You can also sign up with{' '}
                <span>
                  <UntitledButton onClick={() => setIsWithEmail(true)}>
                    email
                  </UntitledButton>
                </span>
              </div>
            </>
          )}
          {isWithEmail && (
            <div className={styles.form}>
              <RegisterForm />
            </div>
          )}
        </div>
        <div
          className={classNames(styles.loginFooter, {
            [styles.natural]: isWithEmail,
          })}
        >
          Already have an account?{' '}
          <Link to={loginUrl ?? '/login/'}>Log in</Link>
        </div>
      </UntitledStyle>
    </AriaModal>
  );
};

export default SignUpModal;
